<template>
	<v-container fluid>
		<portal to="app-bar-title">
			Курс {{ course.name }}
		</portal>

		<v-card
			outlined>
			<v-data-table
				:headers="headers"
				:items="lessons"
				:items-per-page="15"
				:search="search"
				:loading="loading"
				loading-text="Завантаження занять... Зачекайте, будь ласка"
				no-data-text="Немає жодного заняття"
				:item-class="() => 'cursor-pointer'"
				@click:row="routeToLesson">

				<template #top>
										
					<v-toolbar
						color="transparent"
						flat>
						<v-dialog
							v-model="createDialog"
							max-width="800px"
							persistent
							:fullscreen="$vuetify.breakpoint.xsOnly">

							<v-card
								outlined
								:loading="loading">
								<validation-observer
									ref="observer"
									v-slot="{ invalid, handleSubmit }">
									<v-form ref="editForm"
										@submit.prevent="handleSubmit(saveLesson)"
										@reset.native="cancelEditForm">

										<v-card-title>Заняття</v-card-title>

										<v-card-text>
											<v-row>
												<v-col
													cols="2">
													<vv-text-field
														v-model="lesson.order"
														label="Номер"
														name="order"
														hide-details="auto"
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													cols="10">
													<vv-text-field
														v-model="lesson.name"
														label="Назва заняття"
														rules="required"
														name="name"
														hide-details="auto"
														required
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													cols="2">
													<v-switch
														v-model="lesson.is_active"
														label="Активне"
														class="mt-1"
														hide-details="auto"
														inset>
													</v-switch>
												</v-col>
												<v-col
													cols="5">
													<vv-text-field
														v-model="lesson.video_id"
														label="ID відео на vimeo"
														hint="Щось типу 827235335"
														name="video_id"
														hide-details="auto"
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													md="5">
													<vv-autocomplete
														v-model="lesson.course_id"
														:items="courses"
														item-text="name"
														item-value="id"
														label="Курс"
														name="course"
														hide-details="auto"
														outlined
														dense>
													</vv-autocomplete>
												</v-col>
												<v-col
													cols="12">
													<vv-autocomplete
														v-model="lesson.tests"
														:items="tests"
														item-text="name"
														item-value="id"
														label="Тести"
														name="tests"
														hide-details="auto"
														deletable-chips
														return-object
														hide-selected
														small-chips
														multiple
														outlined
														chips
														dense>
													</vv-autocomplete>
												</v-col>
												<v-col
													cols="12">
													<vv-textarea
														v-model="lesson.text"
														label="Текст"
														hint="Введи текст у вигляді html"
														name="description"
														hide-details="auto"
														outlined
														dense>
													</vv-textarea>
												</v-col>
												<v-col
													cols="12">
													<vv-autocomplete
														v-model="lesson.users"
														:items="users"
														label="Учні"
														item-text="full_name"
														item-value="id"
														name="users"
														hide-details="auto"
														deletable-chips
														return-object
														hide-selected
														chips
														small-chips
														multiple
														outlined
														dense>
													</vv-autocomplete>
												</v-col>
											</v-row>

											<h3 class="mt-5">Файли</h3>

											<FileChip
												v-for="(file, key) in lesson.files"
												:key="key"
												:file="file"
												class="ma-1"
												close
												@delete="deleteFile">
												{{ file.file_name }}
											</FileChip>

											<v-btn
												class="my-2"
												icon
												@click="showUploadZone = !showUploadZone">
												<v-icon>mdi-file-plus</v-icon>
											</v-btn>

											<v-expand-transition>
												<FileUpload
													v-if="showUploadZone"
													@upload="addFile">
												</FileUpload>
											</v-expand-transition>

										</v-card-text>

										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn
												depressed
												type="reset">
												Відмінити
											</v-btn>
											<v-btn
												color="primary"
												depressed
												type="submit"
												:disabled="invalid">
												Зберегти
											</v-btn>
										</v-card-actions>
									</v-form>
								</validation-observer>
							</v-card>
						</v-dialog>

						<v-btn
							color="success"
							depressed
							small
							@click="addLesson">
							<v-icon left>mdi-plus</v-icon>
							Додати нове заняття
						</v-btn>

						<v-spacer></v-spacer>

						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Пошук"
							single-line
							hide-details
							outlined
							dense>
						</v-text-field>

					</v-toolbar>
				</template>

				<template #item.is_active="{ value }">
					<v-badge
						:color="value ? 'success' : 'error'"
						dot>
					</v-badge>
				</template>

				<template #item.tests_count="{ value }">
					<v-badge
						v-if="value > 0"
						:content="value"
						color="error">
					</v-badge>
				</template>

				<template #item.files_count="{ value }">
					<v-badge
						v-if="value > 0"
						:content="value"
						color="success">
					</v-badge>
				</template>

				<template #item.users_count="{ value }">
					<v-badge
						v-if="value > 0"
						:content="+value"
						color="amber lighten-1">
					</v-badge>
				</template>

				<template #item.updated_at="{ value }">
					<small class="text--secondary">
						{{ value | datetime }}
					</small>
				</template>

				<template #item.action="{ item }">
					<v-btn
						color="warning"
						icon
						@click.stop="editLesson(item)">
						<v-icon
							small>
							mdi-pencil
						</v-icon>
					</v-btn>

					<v-btn
						color="error"
						icon
						@click.stop="deleteLesson(item)">
						<v-icon
							small>
							mdi-delete
						</v-icon>
					</v-btn>
				</template>

			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import FileUpload from '../components/FileUpload.vue'
import FileChip from '../components/FileChip.vue'

import User from '../models/User'
import Course from '../models/Course'
import Lesson from '../models/Lesson'
import Test from '../models/Test'

export default {
	components: {
		FileUpload,
		FileChip,
	},
	props: {
		id: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		showUploadZone: false,
		lesson: {},
		headers: [
			{
				text: '',
				value: 'is_active',
				align: 'center',
				width: '4em',
			},
			{
				text: 'Номер',
				value: 'order',
			},
			{
				text: 'Назва заняття',
				value: 'name',
			},
			{
				text: 'Курс',
				value: 'course.name',
			},
			{
				text: 'ID Відео',
				value: 'video_id',
			},
			{
				text: 'Тести',
				value: 'tests_count',
			},
			{
				text: 'Файли',
				value: 'files_count',
			},
			{
				text: 'Учні',
				value: 'users_count',
			},
			{
				text: 'Востаннє змінено',
				value: 'updated_at',
			},
			{
				text: 'Дії',
				value: 'action',
				sortable: false,
				align: 'center',
				width: '10em',
			},
		],
		search: '',
		loading: false,
		createDialog: false,
	}),
	computed: {
		users() {
			return User.all()
		},
		courses() {
			return Course.all()
		},
		course() {
			return Course.find(this.id)
		},
		lessons() {
			return Lesson.query()
				.orderBy('order')
				.get()
		},
		tests() {
			return Test.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadUsers() {
			try {
				await User.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadLessons() {
			try {
				this.loading = true
				await Lesson.fetchGetAllByCourse(this.id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список занять',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async loadTests(subjects) {
			try {
				const subjectIds = subjects.map(({ id }) => id)
				await Test.fetchGetAll({
					filter: {
						'category.subject.id': subjectIds.join(','),
					},
				})
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список тестів для цього предмету',
					color: 'error',
				}, { root: true })
			}
		},
		
		async saveLesson() {
			try {
				this.loading = true
				await Lesson.fetchCreateOrUpdate(this.lesson)
				this.$refs.editForm.reset()
				this.$refs.observer.reset()
				this.createDialog = false
				this.notify({
					text: 'Заняття успішно збережено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося зберегти заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async deleteLesson(lesson) {
			try {
				const confirm = await this.$refs.confirm.open(
					'Видалити заняття',
					'Ви дійсно хочете видалити заняття? Цю дію неможливо відмінити.',
					{ color: 'red' })
				if (confirm) {
					this.loading = true
					await Lesson.fetchDelete(lesson.districtId)
					this.notify({
						text: 'Заняття видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.notify({
					text: 'Не вдалося видалити заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		/**
		 * Add file media to lesson
		 *
		 * @param {*} file File model
		 */
		async addFile(file = File) {
			try {
				const { id } = this.lesson

				await Lesson.createFile(id, file)
				await Lesson.fetchGet(this.lesson?.id)

				this.notify({
					text: 'Файл додано',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			}
		},

		/**
		 * Delete file from lesson
		 *
		 * @param {Object} file File model
		 */
		// eslint-disable-next-line max-statements
		async deleteFile({ id: fileId }) {
			try {
				this.loading = true
				const confirm = await this.$swal('confirm', {
					title: 'Дійсно видалити файл?',
					buttonCancel: {
						text: 'Ні',
					},
					buttonOk: {
						text: 'Так',
					},
				})

				if (confirm) {
					const { id } = this.lesson
					await Lesson.deleteFile(id, fileId)
					await Lesson.fetchGet(this.lesson?.id)

					this.notify({
						text: 'Файл видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			} finally {
				this.loading = false
			}
		},

		cancelEditForm() {
			this.lesson = new Lesson()
			this.$refs.editForm.reset()
			this.$refs.observer.reset()
			this.createDialog = false
		},
		addLesson() {
			this.lesson = new Lesson()
			this.createDialog = true
		},
		editLesson(item) {
			this.lesson = new Lesson(item)
			this.createDialog = true
		},

		/**
		 * Go to Lesson page
		 */
		routeToLesson({ id }) {
			this.$router.push({
				name: 'lesson',
				params: {
					id,
				},
			})
		},
	},
	watch: {
		'course.subjects': {
			immediate: true,
			deep: true,
			handler: function(val) {
				if (val) {
					this.loadTests(val)
				}
			},
		},
	},
	mounted() {
		this.loadUsers()
		this.loadCourses()
		this.loadLessons()
	},
}
</script>