<template>
	<v-chip
		v-bind="$attrs"
		v-on="$listeners"
		@click:close="$emit('delete', file)">
		{{ file.file_name }}
	</v-chip>
</template>

<script>
export default {
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
}
</script>
